/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

/**
 * The base breakpoints for the Aconcagua Material.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Aconcagua Material using thie file.
 */

export default {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
