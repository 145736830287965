import { authRef } from '@configs/firebase';
import Swal from 'sweetalert2';

const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const monthLongNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const dayOfWeekShortNames = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];

export const FORM_OPTIONS_PRELOAD_SUFFIX = 'PreloadOptions';

export const getUserWithToken = function () {
  return new Promise((resolve, reject) => {
    authRef.onAuthStateChanged((user) => {
      if (!user) resolve(null);
      else
        user
          .getIdToken()
          .then((token) => {
            resolve({ token, user });
          })
          .catch((e) => {
            reject(e);
          });
    });
  });
};

export const refreshUserToken = () => {
  return authRef.currentUser.getIdToken(true);
};

export const createNestedObject = function (base, names, value) {
  const namesAux = [...names];

  // If a value is given, remove the last name and keep it for later:
  const lastName = arguments.length === 3 ? namesAux.pop() : false;

  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for (let i = 0; i < namesAux.length; i++) {
    base = base[namesAux[i]] = base[namesAux[i]] || {};
  }

  // If a value was given, set it to the last name:
  if (lastName) base = base[lastName] = value;

  // Return the last object in the hierarchy:
  return base;
};

export const toDateObject = function (dirtyDate) {
  if (!dirtyDate) return null;

  if (dirtyDate instanceof Date) return dirtyDate;

  if (dirtyDate._seconds) return new Date(dirtyDate._seconds * 1000);
  else if (typeof dirtyDate === 'string') return new Date(dirtyDate);

  // .toLocaleDateString(
  //     'en-ES'
  //   );
  // const createdAtString = new Date(userTask.createdAt._seconds * 1000).toLocaleDateString(
  //   'en-ES'
  // );
};

export const dateToShortString = function (date) {
  if (!date) return '';

  return date.toLocaleDateString('en-GB');
};

export const parseDateToShortString = function (date) {
  const dateObj = toDateObject(date);

  if (!dateObj) return '';

  return dateToShortString(dateObj);
};

export const parseDateToDateTimeString = function (date) {
  const dateObj = toDateObject(date);

  if (!dateObj) return '';

  const dateString = dateToShortString(dateObj);

  return `${dateString} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const getDayOfWeekShortName = function (date) {
  return dayOfWeekShortNames[date.getUTCDay()];
};

export const getMonthShortName = function (date) {
  return monthShortNames[date.getUTCMonth()];
};

export const getMonthLongName = function (date) {
  return monthLongNames[date.getUTCMonth()];
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }
  return 'just now';
  // return `${Math.floor(seconds)} seconds`;
};

export const MULTIPLE_RELATIONSHIP_SUFFIX = '_SOURCE_ENTITIES';

// eg: obj: { userId: 'bla', otherProps: '', userId_SOURCE_ENTITIES: [{ id: 'bla' } ]}, key: 'userId'
export const getSourceEntityData = function ({ obj, key }) {
  if (!obj) return null;

  const objPropValue = obj[key];

  if (!objPropValue) return null;

  const objPropList = obj[key + MULTIPLE_RELATIONSHIP_SUFFIX];

  if (!objPropList) return null;

  return objPropList.find((item) => {
    return item.id === objPropValue;
  });
};

export const getErrorData = (error) => {
  let code = null;
  let message = error.message;

  if (error.isAxiosError && error.response && error.response.data && error.response.data.message)
    message = error.response.data.message;

  if (error.isAxiosError && error.response && error.response.data && error.response.data.code)
    code = error.response.data.code;

  if (!code && error.isAxiosError && error.response && error.response.status)
    code = `HTTP${error.response.status}`;

  return { code, message };
};

export const handleError = function (error) {
  // eslint-disable-next-line no-console
  console.error(error);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: 'button button-success',
      cancelButton: 'button button-error',
    },
    buttonsStyling: false,
  });

  const { code, message } = getErrorData(error);

  let title = 'Error';

  if (code) title = `Error (HTTP${code})`;

  newSwal.fire(title, message, 'error');
};

export const BACK_ROUTE_KEYWORD = 'back';

export const resolveWithConfirmation = ({
  title,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  return new Promise((resolve, reject) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title,
        text: message,
        showCancelButton: true,
        confirmButtonText: confirmButtonLabel,
        cancelButtonText: cancelButtonLabel,
        reverseButtons: true,
        // allowOutsideClick: () => !Swal.isLoading(),
        allowOutsideClick: false,
      })
      .then((result) => {
        const confirmed = result && result.isConfirmed;
        Swal.close();
        resolve({ confirmed });
      })

      .catch((e) => {
        reject(e);
      });
  });
};

export const showCustomLoading = () => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    newSwal.fire({
      title: 'Loading',
      allowEscapeKey: false,
      allowOutsideClick: false,
      // timer: 2000,
      didOpen: () => {
        Swal.showLoading();
        resolve();
      },
    });
  });
};

export const closeCustomLoading = () => {
  Swal.close();
};

export const enumValuesToArray = (enumType) => {
  return Object.keys(enumType).filter((item) => {
    return isNaN(Number(item));
  });
};

export const getFirstNameFromString = (str) => {
  if (!str || !str.length) return '';

  return str.split(' ')[0].trimEnd();
};

export const formatMoney = function (amountArg, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    let amount = amountArg;

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const ii = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const jj = ii.length > 3 ? ii.length % 3 : 0;

    return (
      negativeSign +
      (jj ? ii.substring(0, jj) + thousands : '') +
      ii.substring(jj).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - ii)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    throw e;
  }
};

export const shortenContractAddress = (address) => {
  if (!address) return address;
  return address.substr(address.length - 8);
};

const roundTwoDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const shortenCompanyValuation = (valuation, noCurrency) => {
  if (!valuation) return valuation;

  let valuationRawText = '';

  if (valuation < 1000000) valuationRawText = `${roundTwoDecimals(valuation) / 1000} K`;
  else valuationRawText = `${roundTwoDecimals(valuation) / 1000000} M`;

  if (noCurrency) return valuationRawText;

  return `${valuationRawText}`;
};

export const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const loadStylesheet = (scriptToAppend) => {
  const script = document.createElement('link');
  document.body.appendChild(script);
  script.async = true;
  script.href = scriptToAppend;
  script.rel = 'stylesheet';
};

export const analyticsTag = function (data) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(data);
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
