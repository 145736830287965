/* eslint-disable no-console */
import { useReducer, useEffect, useContext } from 'react';
import { CompanyContext } from './company.context';
import { useCookies } from 'react-cookie';

import { AuthContext } from '@contexts/auth/auth.context';
import { listGrantedCompanies } from 'services/companiesServices';

const INITIAL_STATE = { all: [], current: null, investCompany: null, status: false };

function reducer(state, action) {
  console.log('Context: Auth - Action: ', action);

  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_INVEST_COMPANY':
      return {
        ...state,
        ...action.payload,
      };

    case 'REFRESH_DATA':
      return {
        ...state,
        status: false,
      };

    default:
      return state;
  }
}

// export const AuthProvider: React.FunctionComponent<any> = ({ children }) => {
export const CompanyProvider = ({ children }) => {
  const [grantedCompaniesData, grantedCompaniesDataDispatch] = useReducer(reducer, INITIAL_STATE);
  const [cookies, setCookie] = useCookies([]);

  const {
    authState,
    // authDispatch,
  } = useContext(AuthContext);

  useEffect(() => {
    if (!authState || !authState.authStatusReported || !authState.isAuthenticated) return;

    async function doAsync() {
      try {
        const result = await listGrantedCompanies();

        let company = null;
        if (result) {
          // busco la que esta cacheada por cookie
          // o la primera que encuentre dentro del array de claims
          company = result.items.find((item) => {
            if (cookies && cookies.currentCompanyId) {
              return item.id === cookies.currentCompanyId;
            }
          });

          if (!company && result.items.length) company = result.items[0];
        }

        if (!company) {
          grantedCompaniesDataDispatch({
            payload: {
              all: [],
              current: null,
              status: true,
            },
            type: 'SET_COMPANY',
          });

          return;
        }

        grantedCompaniesDataDispatch({
          payload: {
            all: result.items,
            current: company,
            status: true,
          },
          type: 'SET_COMPANY',
        });
      } catch (e) {
        console.log('Error fetching company', e);
        throw e;
      }
    }

    doAsync();
  }, [cookies.currentCompanyId, authState, grantedCompaniesData.status]);

  const setInvestCompanyDispatch = (company) => {
    grantedCompaniesDataDispatch({
      payload: {
        investCompany: company,
      },
      type: 'SET_INVEST_COMPANY',
    });
  };

  const refreshDataDispatch = () => {
    grantedCompaniesDataDispatch({
      type: 'REFRESH_DATA',
    });
  };

  return (
    <CompanyContext.Provider
      value={{ grantedCompaniesData, refreshDataDispatch, setInvestCompanyDispatch }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
