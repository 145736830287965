/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

// Sancho React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

export default {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`,
    },
  },
};
