/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

export default {
  defaultProps: {
    color: 'transparent',
  },

  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },
};
