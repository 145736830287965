/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

// Sancho React base styles
import typography from 'assets/theme/base/typography';

// Sancho React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { size } = typography;

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};
