// import { getUserWithToken } from 'src/@core/coreHelper';
import { authRef } from '../../configs/firebase';
// import { dynamicCreate, dynamicGet } from '../../services/entitiesDynamicServices';
// import { Collections, ILogging } from '../../types/collectionsTypes';
export interface ILogging {
  message: string;
  interactionType?: string;
  logLevelType: string;
  opsUserId?: string;
  channelType: string;
  userId: string;
  email?: string;
  moduleName?: string;
  data?: any;
  displayName?: string;
}

export enum LoggingInteractionType {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum LoggingLevelType {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  INTERACTION = 'interaction',
}

export enum LoggingChannelType {
  WEB = 'web',
  PLATFORM = 'platform',
  CHAT = 'chat',
  MEET = 'meet',
}

export interface ILoggingArg {
  moduleName?: string;
  opsUserId?: string;

  data?: any;
  message: string;

  interactionType?: LoggingInteractionType;
  logLevelType?: LoggingLevelType;
  channelType?: LoggingChannelType;
}

export class ClientSideLogging {
  static async log({ log }: { log: ILoggingArg }): Promise<any> {
    try {
      const user = authRef.currentUser;

      const itemData: ILogging = {
        ...log,
        email: user?.email ?? undefined,
        displayName: user?.displayName ?? undefined,
        userId: user?.uid ?? '',
        logLevelType: log.logLevelType ?? LoggingLevelType.INFO,
        channelType: log.channelType ?? LoggingChannelType.PLATFORM,
      };
      console.log('ClientSideLogging.log', log, itemData);
      //   return dynamicCreate({ params: `/cms/${Collections.LOGGING}/mine`, data: itemData });
    } catch (e: any) {
      console.error('ERROR LOGGING !!!', e.message, e);
    }
  }

  static async debug({
    log,
  }: {
    organizationId: string;
    auditUid: string;
    log: ILoggingArg;
  }): Promise<void> {
    log.logLevelType = LoggingLevelType.DEBUG;

    if (!log.channelType) log.channelType = LoggingChannelType.WEB;
    if (!log.interactionType) log.interactionType = LoggingInteractionType.GET;

    await ClientSideLogging.log({
      log,
    });
  }

  static async info({
    log,
  }: {
    organizationId: string;
    auditUid: string;
    log: ILoggingArg;
  }): Promise<void> {
    log.logLevelType = LoggingLevelType.INFO;

    if (!log.channelType) log.channelType = LoggingChannelType.WEB;
    if (!log.interactionType) log.interactionType = LoggingInteractionType.GET;

    await ClientSideLogging.log({
      log,
    });
  }

  static async error({ log }: { log: ILoggingArg }): Promise<void> {
    log.logLevelType = LoggingLevelType.ERROR;

    if (!log.channelType) log.channelType = LoggingChannelType.WEB;
    if (!log.interactionType) log.interactionType = LoggingInteractionType.GET;

    await ClientSideLogging.log({
      log,
    });
  }
  static async warn({ log }: { log: ILoggingArg }): Promise<void> {
    log.logLevelType = LoggingLevelType.WARN;

    if (!log.channelType) log.channelType = LoggingChannelType.WEB;
    if (!log.interactionType) log.interactionType = LoggingInteractionType.GET;

    await ClientSideLogging.log({
      log,
    });
  }

  static async interaction({ log }: { log: ILoggingArg }): Promise<void> {
    log.logLevelType = LoggingLevelType.INTERACTION;

    if (!log.channelType) log.channelType = LoggingChannelType.WEB;
    if (!log.interactionType) log.interactionType = LoggingInteractionType.GET;

    await ClientSideLogging.log({
      log,
    });
  }
}
