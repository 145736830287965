/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sancho React Base Styles
import borders from 'assets/theme/base/borders';

// Sancho React Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.xl,
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },

    media: {
      width: 'auto',
    },
  },
};
