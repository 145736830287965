'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.Collections = void 0;
let Collections;
(function (Collections) {
  Collections['LEADS'] = 'leads';
  Collections['USERS'] = 'users';
  Collections['COMPANIES'] = 'companies';
  Collections['COMPANY_EMPLOYEES'] = 'companyEmployees';
  Collections['COMPANY_CLIENTS'] = 'companyClients';
  Collections['COMPANY_KEY_PEOPLE'] = 'companyKeyPeople';
  Collections['COMPANY_HISTORY'] = 'companyHistory';
  Collections['VAULTS'] = 'vaults';
  Collections['VAULT_TRANSACTIONS'] = 'vaultTransactions';
  Collections['VAULT_INSTALLMENTS'] = 'vaultInstallments';
  Collections['TRANSACTION_REQUESTS'] = 'transactionRequests';
  Collections['REMINDERS'] = 'reminders';
  Collections['INDUSTRIES'] = 'industries';
  Collections['COMPANY_DOCS'] = 'companyDocs';
  Collections['ACTIVES'] = 'actives';
  Collections['USER_INVESTMENTS'] = 'userInvestments';
  Collections['USER_SAFES'] = 'userSafes';
  Collections['ACTIVE_COMPOSITION'] = 'activeComposition';
  Collections['COMPANY_FUNDINGS'] = 'companyFundings';
  Collections['COMPANY_FUNDING_INVESTORS'] = 'companyFundingsInvestors';
  Collections['COMPANY_PORTFOLIO_MANAGER'] = 'companyPortfolioManager';
  Collections['COMPANY_NOTES'] = 'companyNotes';
  Collections['COMPANY_INDICATORS'] = 'companyIndicators';
  Collections['LOGGING'] = 'logging';
})((Collections = exports.Collections || (exports.Collections = {})));

// export enum Collections {
//   LEADS = 'leads',
//   USERS = 'users',
//   PROVIDERS = 'providers',
//   PRODUCTS = 'products',
//   PACKAGES = 'packages',
//   STAFF = 'staff',
//   ASPECTS = 'aspects',
//   LEVELS = 'levels',
//   TASKS = 'tasks',
//   USER_TASKS = 'userTasks',
//   USER_TASKS_HISTORY = 'userTasksHistory',
//   USER_TOUCHPOINTS = 'userTouchpoints',
//   ATTACHMENTS = 'attachments',
//   USERS_BY_STAFF = 'usersByStaff',
//   PROGRESS_OPTIONS = 'progressOptions',
//   HOOKED_EVENTS = 'hookedEvents',
//   USER_DYNAMIC_ATTRIBUTES = 'userDynamicAttributes',
//   USER_ATTRIBUTES_TYPES = 'userAttributesTypes',
//   USER_PRODUCTS = 'userProducts',
//   USER_WELL_BEING_ATTRIBUTES = 'userWellBeingAttributes',
//   OAUTH2_TOKENS = 'oauth2Tokens',
//   USER_CALENDARS = 'userCalendars',
//   USER_CALENDAR_EVENTS = 'userCalendarEvents',
//   COMPANIES = 'companies',
//   COMPANY_EMPLOYEES = 'companyEmployees',
//   COMPANY_CLIENTS = 'companyClients',
//   COMPANY_PROFILES = 'companyProfiles',
//   COMPANY_DEPARTMENTS = 'companyDepartments',
//   COMPANY_SURVEYS = 'companySurveys',
//   COMPANY_SURVEY_QUESTIONS = 'companySurveyQuestions',
// }
