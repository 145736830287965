/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

// Sancho React base styles
import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';

const { borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};
