/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sancho React base styles
import colors from 'assets/theme/base/colors';

const { dark } = colors;

export default {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};
