/**
=========================================================
* React - v3.0.0
=========================================================


 =========================================================


*/

export default {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};
