/**
=========================================================
* React - v3.0.0
=========================================================
*/

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
