/* eslint-disable no-console */
import axios from 'axios';

import { getHttpBearerConfig, listArgsToQueryString } from '@services/httpServices';
import { getUserWithToken } from '@core/helpers/coreHelper';

//import API URL from appConfig
import { API_URL_LOGGING } from '@configs/appConfig';

// const API_URL = 'http://your-backend-api-url.com'; // Replace with your actual backend API URL

import { Collections } from '@types/collectionsTypes';

export const SERVICE_COLLECTION_NAME = Collections.LOGGING;

export const listLoggingsByUser = (args) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      if (!args || !args.userId) {
        reject('invalid args missing');
        return;
      }

      const config = getHttpBearerConfig(uwt ? uwt.token : null);

      const userId = args.userId;

      let filters = null;
      if (args) filters = args.filters;

      const qs = listArgsToQueryString({ filters });

      console.log('REQUESTING ', API_URL_LOGGING);
      axios
        .get(`${API_URL_LOGGING}/by-user/${userId}${qs}`, config)
        .then((response) => {
          if (!response || !response.data) {
            console.error('Invalid response');
            throw new Error('Invalid response');
          }

          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  });
};

export const createLogByUser = (args) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      const config = getHttpBearerConfig(uwt ? uwt.token : null);

      const { userId, logData } = args;
      axios
        // .post(`${API_URL_LOGGING}/by-user/${userId}`, logData, config)
        .post(`${API_URL_LOGGING}/create-mine`, logData, config)
        .then((response) => {
          if (!response || !response.data) {
            console.error('Invalid response');
            throw new Error('Invalid response');
          }

          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  });
};
